import { render } from "./ChartOfStoryDetails.vue?vue&type=template&id=00359fbc&scoped=true"
import script from "./ChartOfStoryDetails.vue?vue&type=script&lang=ts"
export * from "./ChartOfStoryDetails.vue?vue&type=script&lang=ts"

import "./ChartOfStoryDetails.vue?vue&type=style&index=0&id=00359fbc&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-00359fbc"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTable,QTr,QTh,QTd});
